import React, { Fragment, useEffect, useState } from 'react';
import BreadCrumb from "../../components/BreadCrumb";
import BusinessNews from "../../components/BusinessNews";
import FontAwesome from "../../components/uiStyle/FontAwesome";
import { Link } from "react-router-dom";
import WidgetTab from "../../components/WidgetTab";
import WidgetTrendingNews from "../../components/WidgetTrendingNews";
import NewsLetter from "../../components/NewsLetter";
import FollowUs from "../../components/FollowUs";

// images
import business1 from '../../doc/img/business/business1.jpg';
import business2 from '../../doc/img/business/business2.jpg';
import business3 from '../../doc/img/business/business3.jpg';
import banner2 from "../../doc/img/bg/sidebar-1.png";
import BannerSection from "../../components/BannerSection";
import { useParams } from "react-router-dom";

import { loadArticlesAction, loadCategoriesAction, addSubscriptionAction, resetArticleReducer } from '../../store/actions';
import { connect } from 'react-redux';
import { toast } from "react-toastify";
import { Helmet } from 'react-helmet';

const businessNews = [
    {
        image: business1,
        category: 'uiux.subash',
        date: 'March 26, 2020',
        title: 'Copa America: Luis Suarez from devastated US',
        body: 'The property, complete with 30-seat screening from room, a 100-seat amphitheater and a swimming pond with…'
    },
    {
        image: business2,
        category: 'uiux.subash',
        date: 'March 26, 2020',
        title: 'Copa America: Luis Suarez from devastated US',
        body: 'The property, complete with 30-seat screening from room, a 100-seat amphitheater and a swimming pond with…'
    },
    {
        image: business3,
        category: 'uiux.subash',
        date: 'March 26, 2020',
        title: 'Copa America: Luis Suarez from devastated US',
        body: 'The property, complete with 30-seat screening from room, a 100-seat amphitheater and a swimming pond with…'
    },
    {
        image: business1,
        category: 'uiux.subash',
        date: 'March 26, 2020',
        title: 'Copa America: Luis Suarez from devastated US',
        body: 'The property, complete with 30-seat screening from room, a 100-seat amphitheater and a swimming pond with…'
    },
    {
        image: business2,
        category: 'uiux.subash',
        date: 'March 26, 2020',
        title: 'Copa America: Luis Suarez from devastated US',
        body: 'The property, complete with 30-seat screening from room, a 100-seat amphitheater and a swimming pond with…'
    },
    {
        image: business3,
        category: 'uiux.subash',
        date: 'March 26, 2020',
        title: 'Copa America: Luis Suarez from devastated US',
        body: 'The property, complete with 30-seat screening from room, a 100-seat amphitheater and a swimming pond with…'
    },
    {
        image: business2,
        category: 'uiux.subash',
        date: 'March 26, 2020',
        title: 'Copa America: Luis Suarez from devastated US',
        body: 'The property, complete with 30-seat screening from room, a 100-seat amphitheater and a swimming pond with…'
    },
    {
        image: business3,
        category: 'uiux.subash',
        date: 'March 26, 2020',
        title: 'Copa America: Luis Suarez from devastated US',
        body: 'The property, complete with 30-seat screening from room, a 100-seat amphitheater and a swimming pond with…'
    },
];

const CategoryView = ({ loadArticlesAction, articles, addSubscriptionAction, subscriptionAdded, resetArticleReducer }) => {
    const { id, title } = useParams()
    const [modal, setModal] = useState(false);
    const articlesByid = articles?.filter((e) => e?.attributes?.category_id === id)

    useEffect(() => {
        loadArticles()
    }, [])

    useEffect(() => {
        if (subscriptionAdded) {
            toast.success("Submitted Successfully")
            toggle()
            resetArticleReducer()
        }
        window.scrollTo(0, 0);
    }, [subscriptionAdded, id])

    const loadArticles = () => {
        loadArticlesAction()
    }

    const toggle = () => setModal(!modal);

    const handleAddData = (data) => {
        addSubscriptionAction(data)
    }

    return (
        <Fragment>
            <Helmet>
                <title>Repute - {title?.replaceAll("-", " ")}</title>
                <meta name="description" content="Repute is a business media firm established to bring together entities of repute." />
            </Helmet>
            <BreadCrumb title={title?.replaceAll("-", " ")} />
            <div className="archives padding-top-30">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-lg-8">
                            <div className="businerss_news">
                                <div className="row">
                                    <div className="col-12 align-self-center">
                                        <div className="categories_title">
                                            <h5>Category: <Link to="/">{title?.replaceAll("-", " ")}</Link></h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <BusinessNews data={articlesByid} headerHide={true} businessNews={businessNews} />
                                    </div>
                                </div>
                                {/* <div className="row">
                                    <div className="col-12">
                                        <div className="cpagination">
                                            <nav aria-label="Page navigation example">
                                                <ul className="pagination">
                                                    <li className="page-item">
                                                        <Link className="page-link" to="/" aria-label="Previous">
                                                            <span aria-hidden="true"><FontAwesome
                                                                name="caret-left" /></span>
                                                        </Link>
                                                    </li>
                                                    <li className="page-item">
                                                        <Link className="page-link" to="/">1</Link>
                                                    </li>
                                                    <li className="page-item">
                                                        <Link className="page-link" to="/">..</Link>
                                                    </li>
                                                    <li className="page-item">
                                                        <Link className="page-link" to="/">5</Link>
                                                    </li>
                                                    <li className="page-item">
                                                        <Link className="page-link" to="/" aria-label="Next">
                                                            <span aria-hidden="true"><FontAwesome
                                                                name="caret-right" /></span>
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </nav>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <WidgetTab data={articlesByid?.slice(0, 3)?.reverse()} />
                            <WidgetTrendingNews data={articles.slice(7, 12)} />
                            <NewsLetter field_type="partnership" title={"PARTNER WITH US"} subTitle={"Engage and interact with audiences consisting of business leaders, decision makers, change makers of Repute throughout the region."} modal={modal} handleAddData={handleAddData} handleClick={toggle} />
                            {/* <FollowUs title="Follow Us" /> */}
                            {/* <div className="banner2 mb30">
                                <Link to="/">
                                    <img src={banner2} alt="thumb" />
                                </Link>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className="space-70" />
            {/* <BannerSection /> */}
        </Fragment>
    );
};



const mapStateToProps = ({ articlesReducer }) => {
    const { articles, categories, subscriptionAdded } = articlesReducer
    return { articles, categories, subscriptionAdded }
}


const mapActionsToProps = {
    loadArticlesAction,
    loadCategoriesAction,
    addSubscriptionAction,
    resetArticleReducer
}

export default connect(mapStateToProps, mapActionsToProps)(CategoryView)
import React, { Component } from 'react';
import { Link } from "react-router-dom";
import WidgetTab from "../WidgetTab";
import Slider from "react-slick";
import "../../../node_modules/slick-carousel/slick/slick.css";
import FontAwesome from "../uiStyle/FontAwesome";
import ModalVideo from 'react-modal-video'

// images
import gsil1 from '../../doc/img/blog/post_gsi1.jpg';
import gsil2 from '../../doc/img/blog/post_gsi2.jpg';
import gsil3 from '../../doc/img/blog/post_gsi3.jpg';
import gsil4 from '../../doc/img/blog/post_gsi4.jpg';
import gsil5 from '../../doc/img/blog/post_gsi5.jpg';
import gsil6 from '../../doc/img/blog/post_gsi6.jpg';
import gsil7 from '../../doc/img/blog/post_gsi7.jpg';
// import sliderImg1 from '../../doc/img/header/sider-top.jpg';
// import sliderImg2 from '../../doc/img/header/sider-top2.jpg';
import sliderImg1 from '../../assets/img/header/sider-top.jpg';
import sliderImg2 from '../../assets/img/header/sider-top2.jpg';
import markdownToTxt from 'markdown-to-txt';

import './style.scss';

const thumbs = [sliderImg1, sliderImg2, sliderImg1, sliderImg2, sliderImg1, sliderImg2, sliderImg1, sliderImg2, sliderImg1];
const postSlider = [
    {
        image: sliderImg1,
        title: 'Japan’s virus success has puzzled the world. Is its luck running out?',
        body: 'The property, complete with 30-seat screening from room, a 100-seat amphitheater and a swimming pond with sandy shower…',
        category: 'TECHNOLOGY',
        date: 'March 26, 2020'
    },
    {
        image: sliderImg2,
        title: 'Japan’s virus success has puzzled the world. Is its luck running out?',
        body: 'The property, complete with 30-seat screening from room, a 100-seat amphitheater and a swimming pond with sandy shower…',
        category: 'TECHNOLOGY',
        date: 'March 26, 2020'
    },
    {
        image: sliderImg2,
        title: 'Copa America: Luis Suarez from devastated US America',
        body: 'The property, complete with 30-seat screening from room, a 100-seat amphitheater and a swimming pond with sandy shower…',
        category: 'TECHNOLOGY',
        date: 'March 26, 2020'
    },
    {
        image: sliderImg1,
        title: 'Japan’s virus success has puzzled the world. Is its luck running out?',
        body: 'The property, complete with 30-seat screening from room, a 100-seat amphitheater and a swimming pond with sandy shower…',
        category: 'TECHNOLOGY',
        date: 'March 26, 2020'
    },
    {
        image: sliderImg2,
        title: 'Copa America: Luis Suarez from devastated US America',
        body: 'The property, complete with 30-seat screening from room, a 100-seat amphitheater and a swimming pond with sandy shower…',
        category: 'TECHNOLOGY',
        date: 'March 26, 2020'
    },
    {
        image: sliderImg1,
        title: 'Japan’s virus success has puzzled the world. Is its luck running out?',
        body: 'The property, complete with 30-seat screening from room, a 100-seat amphitheater and a swimming pond with sandy shower…',
        category: 'TECHNOLOGY',
        date: 'March 26, 2020'
    },
    {
        image: sliderImg2,
        title: 'Copa America: Luis Suarez from devastated US America',
        body: 'The property, complete with 30-seat screening from room, a 100-seat amphitheater and a swimming pond with sandy shower…',
        category: 'TECHNOLOGY',
        date: 'March 26, 2020'
    },
    {
        image: sliderImg1,
        title: 'Japan’s virus success has puzzled the world. Is its luck running out?',
        body: 'The property, complete with 30-seat screening from room, a 100-seat amphitheater and a swimming pond with sandy shower…',
        category: 'TECHNOLOGY',
        date: 'March 26, 2020'
    },
    {
        image: sliderImg2,
        title: 'Copa America: Luis Suarez from devastated US America',
        body: 'The property, complete with 30-seat screening from room, a 100-seat amphitheater and a swimming pond with sandy shower…',
        category: 'TECHNOLOGY',
        date: 'March 26, 2020'
    },
];

function SampleNextArrow(props) {
    const { className, onClick } = props;
    return (
        <div onClick={onClick} className={`${className} slider_arrow arrow_right slick-arrow`}>
            <FontAwesome name="angle-right" />
        </div>
    );
}

function SamplePrevArrow(props) {
    const { className, onClick } = props;
    return (
        <div onClick={onClick} className={`${className} slider_arrow arrow_left slick-arrow`}>
            <FontAwesome name="angle-left" />
        </div>
    );
}

function formatDate(dateStr) {
    const date = new Date(dateStr);
    const formattedDate = date.toLocaleDateString("en-US", { month: "long", day: "numeric", year: "numeric" });
    return formattedDate;
}

class PostGallery extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nav1: null,
            nav2: null,
            vModal: false,
            videoId: '0r6C3z3TEKw'
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps?.data !== this.props?.data) {
            this.setState({
                nav1: this.slider1,
                nav2: this.slider2
            });
        }
    }

    modalHandler = (value) => {
        this.setState({
            vModal: value
        })
    };



    render() {
        const { className } = this.props;
        const { nav1, nav2, vModal, videoId } = this.state;

        const navSettings = {
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />,
            slidesToShow: 8,
            swipeToSlide: true,
            focusOnSelect: true,
            centerMode: true,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 8,
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 5,
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 3,
                    }
                },
            ],
             autoplay: {
                disableOnInteraction: true
            },
            autoplaySpeed : 6000

        };
        return (
            <div className={`post_gallary_area mb40 ${className}`}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="row">
                                <div className="col-xl-8">
                                    <div className="slider_demo2">
                                        {this.props?.data?.length > 0 &&
                                            <Slider
                                                asNavFor={nav2}
                                                arrows={false}
                                                fade={true}
                                                ref={slider => (this.slider1 = slider)}
                                            >
                                                {this.props?.data?.map((item, i) => (
                                                    <div key={i} className="single_post post_type6 xs-mb30">
                                                        <Link to={`/post/${item?.attributes?.title?.replaceAll(" ", "-")}/${item?.id}`}>
                                                            <div className="post_img gradient1">
                                                                <img style={{ width: 730, height: 500 }} src={item?.attributes?.url} alt="thumb" />
                                                                {/* <span onClick={() => this.modalHandler(true)}
                                                              className="tranding"><FontAwesome
                                                            name="trending"/></span> */}
                                                            </div>
                                                        </Link>
                                                        <div className="single_post_text">
                                                            <div className="meta meta_separator1">
                                                                <Link to={`/category/${item?.attributes?.categoryTitle?.replaceAll(" ", "-")}/${item?.attributes?.category_id}`} >{item?.attributes?.categoryTitle}</Link>
                                                                <Link to="#">{formatDate(item?.attributes?.publishedOn ? item?.attributes?.publishedOn : item?.attributes?.createdAt)} - {item?.attributes?.readingTime}</Link>
                                                            </div>
                                                            <h4><Link className="play_btn"
                                                                to={`/post/${item?.attributes?.title?.replaceAll(" ", "-")}/${item?.id}`}>{item.attributes?.title}</Link></h4>
                                                            <div className="space-10" />
                                                            <p className="post-p">{item.attributes?.content && markdownToTxt(item.attributes?.content)}</p>
                                                        </div>
                                                    </div>
                                                ))}
                                            </Slider>}
                                    </div>
                                    <div className="slider_demo1">
                                        {this.props?.data?.length > 0 &&
                                            <Slider
                                                ref={slider => (this.slider2 = slider)}
                                                asNavFor={nav1}
                                                {...navSettings}
                                            >
                                                {this.props?.data?.map((item, i) => (
                                                    <div key={i} className="single_gallary_item">
                                                        <img style={{ width: 79, height: 54 }} src={item?.attributes?.url} alt="thumb" />
                                                    </div>
                                                ))}
                                            </Slider>
                                        }
                                    </div>
                                </div>
                                <div className="col-xl-4">
                                    <WidgetTab dark={true} data={this.props?.data?.slice(3, 8)} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ModalVideo channel='youtube' isOpen={vModal} videoId={videoId}
                    onClose={() => this.modalHandler(false)} />
            </div>
        );
    }
}

export default PostGallery;
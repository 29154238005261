import React, { useState, useRef } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import SimpleReactValidator from 'simple-react-validator';

const organizations = ["Information Technology", "Manufacturing", "Ecommerce", "Business", "Entrepreneur", "Educational Institute", "Start-Up & Founders", "Retail", "Trading", "Healthcare", "Hotels & Restaurants", "Supply Chain & Logistics", "Aerospace", "Agriculture", "Constructions & Real Estate", "Public Sector", "NGO", "Human Resources", "Law Firm", "Arts & Crafts", "Entertainment", "Hospitality", "Logistics", "Food", "Others"]

const NewsLetter = ({ className, input_white, titleClass, handleAddData, modal, handleClick, title, subTitle, field_type, customButton }) => {
    const simpleValidator = useRef(new SimpleReactValidator());
    const [fields, setFields] = useState({ firstName: "", lastName: "", contactNumber: "", emailId: "", city: "", country: "", nameOfOrganization: "", jobTitle: "", typeOfOrganization: "" })
    const [, forceUpdate] = useState();

    const handleChange = (event,) => {
        setFields({ ...fields, [event.target.name]: event.target.value });
    }

    const handleSave = () => {
        const formValid = simpleValidator.current.allValid()
        console.log('saving...')
        if (!formValid) {
            console.log('form not valid...')
            simpleValidator.current.showMessages()
            forceUpdate(1)
            return
        }
        let payload = { ...fields }
        payload["subscriptionType"] = field_type
        handleAddData({ data: payload })
    }

    return (
        <div className={`${customButton ? "": "box widget news_letter"} mb30 ${className ? className : ''}`}>
            {customButton ? customButton : <><h2 className={`widget-title ${titleClass}`}>{title}</h2>
                <p>{subTitle}</p>
                <div className="space-20" />
                <div className="signup_form">
                    <button type="button" className='subscribe-btn' onClick={handleClick}>{title}</button>
                    <div className="space-10" />
                </div></>}
            <Modal isOpen={modal} fade={false} toggle={handleClick}>
                <ModalHeader toggle={handleClick}>{title}</ModalHeader>
                <ModalBody>
                    <div className="enquiry_form p-2">
                        <div className="row">
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-lg-4 mb-4">
                                        <input name="firstName"
                                            type="text"
                                            onChange={handleChange}
                                            placeholder="First Name*"
                                        />
                                        {simpleValidator.current.message('firstName', fields?.firstName, 'required')}
                                    </div>
                                    <div className="col-lg-4 mb-4">
                                        <input name="lastName"
                                            type="text"
                                            placeholder="Last Name"
                                            onChange={handleChange} />
                                        {simpleValidator.current.message('lastName', fields?.lastName, '')}
                                    </div>
                                    <div className="col-lg-4 mb-4">
                                        <input name="contactNumber"
                                            type="number"
                                            placeholder="Contact Number*"
                                            onChange={handleChange} />
                                        {simpleValidator.current.message('contactNumber', fields?.contactNumber, 'required')}
                                    </div>
                                    <div className="col-lg-4 mb-4">
                                        <input name="emailId"
                                            type="email"
                                            placeholder="Email ID*"
                                            onChange={handleChange}
                                        />
                                        {simpleValidator.current.message('emailId', fields?.emailId, 'required|email')}
                                    </div>
                                    <div className="col-lg-4 mb-4">
                                        <input name="city"
                                            type="text"
                                            placeholder="City*"
                                            onChange={handleChange}
                                        />
                                        {simpleValidator.current.message('city', fields?.city, 'required')}
                                    </div>
                                    <div className="col-lg-4 mb-4">
                                        <input name="country"
                                            type="text"
                                            placeholder="Country*"
                                            onChange={handleChange}
                                        />
                                        {simpleValidator.current.message('country', fields?.country, 'required')}
                                    </div>
                                    <div className="col-lg-6 mb-4">
                                        <input name="nameOfOrganization"
                                            type="text"
                                            placeholder="Name of your organization*"
                                            onChange={handleChange} />

                                        {simpleValidator.current.message('nameOfOrganization', fields?.nameOfOrganization, 'required')}
                                    </div>
                                    <div className="col-lg-6 mb-4">
                                        <select defaultValue="" class="form-select" name='typeOfOrganization' onChange={handleChange}>
                                            <option disabled value="">Type of organization*</option>
                                            {organizations?.map((value) => (
                                                <option value={value}>{value}</option>
                                            ))}
                                        </select>
                                        {simpleValidator.current.message('typeOfOrganization', fields?.typeOfOrganization, 'required')}

                                    </div>
                                    <div className="col-lg-6 mb-4">
                                        <input name="jobTitle"
                                            type="text"
                                            placeholder="Job Title*" onChange={handleChange} />
                                        {simpleValidator.current.message('jobTitle', fields?.jobTitle, 'required')}
                                    </div>
                                </div>
                                <div>
                                    By submitting the form above, you authorize us to connect with you and periodically present to you emails containing information on various initiatives that may help you in your professional endeavors. (You may however click on unsubscribe at the end of the emails you shall receive anytime).
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" type="submit" onClick={handleSave}>
                        Submit
                    </Button>{' '}
                    <Button color="secondary" onClick={handleClick}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default NewsLetter;
import React, { useState, useRef } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import FontAwesome from '../uiStyle/FontAwesome';
import { toast } from "react-toastify";
import "./styles.scss"
import { LinkedinShareButton, LinkedinIcon, FacebookIcon, FacebookShareButton, TwitterIcon, TwitterShareButton, EmailIcon, EmailShareButton, WhatsappIcon, WhatsappShareButton,TelegramIcon,TelegramShareButton } from 'react-share';

const ShareModal = ({  modal, handleClick, article}) => {
    return (
        <Modal isOpen={modal} fade={false} toggle={handleClick} className="share_popup_modal">
            <ModalBody>
                <div className='d-flex justify-content-between align-items-center'>
                    <h6>Share</h6>
                    <FontAwesome name="close" onClick={handleClick} />
                </div>
                <div className='mt-3 mb-3 d-flex justify-content-between'>
                    <a className='icon_circle'>
                        <LinkedinShareButton title={article?.title} url={window.location.href} source={window.location.href} summary={article?.content}>
                            <LinkedinIcon size={45} round={true} />
                        </LinkedinShareButton>
                    </a>
                    <a className='icon_circle'>
                        <FacebookShareButton url={window.location.href} quote={article?.title}>
                            <FacebookIcon size={45} round={true} />
                        </FacebookShareButton>
                    </a>
                    <a className='icon_circle'>
                        <TwitterShareButton url={window.location.href} title={article?.title}>
                            <TwitterIcon size={45} round={true} />
                        </TwitterShareButton>
                    </a>
                    <a className='icon_circle'>
                        <EmailShareButton url={window.location.href} subject={article?.title} body={article?.content} >
                            <EmailIcon size={45} round={true} />
                        </EmailShareButton>
                    </a>
                    <a className='icon_circle'>
                        <WhatsappShareButton url={window.location.href} title={article?.title}>
                            <WhatsappIcon size={45} round={true} />
                        </WhatsappShareButton>
                    </a>
                    <a className='icon_circle'>
                        <TelegramShareButton url={window.location.href} title={article?.title}>
                            <TelegramIcon size={45} round={true} />
                        </TelegramShareButton>
                    </a>
                </div>
                <input className='copy-clipboard-input' value={window.location.href} readOnly />
                <button className='copy-btn mt-3' onClick={()=> {navigator.clipboard.writeText(window.location.href);toast.success("Link copied to clipboard")}}>Copy to clipboard</button>
            </ModalBody>
        </Modal>
    );
};

export default ShareModal;
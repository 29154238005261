import React, { Fragment } from 'react';
import FontAwesome from "../uiStyle/FontAwesome";
import { Link } from "react-router-dom";

// images
import trendbig1 from '../../doc/img/trending/trendbig1.jpg';
import transm4 from '../../doc/img/trending/transm4.jpg';
import transm5 from '../../doc/img/trending/transm5.jpg';
import transm6 from '../../doc/img/trending/transm6.jpg';
import { formatDate } from '../../utils/commonFunctions';
import markdownToTxt from 'markdown-to-txt';

const posts = [
    {
        photo: transm4,
        category: 'TECHNOLOGY',
        date: 'March 26, 2020',
        title: 'Nancy Zhang a Chinese busy woman and Dhaka',
    },
    {
        photo: transm5,
        category: 'TECHNOLOGY',
        date: 'March 26, 2020',
        title: 'U.S. Response subash says he will label regions by risk of…',
    },
    {
        photo: transm6,
        category: 'TECHNOLOGY',
        date: 'March 26, 2020',
        title: 'Venezuela elan govt and opposit the property collect',
    },
];

const WidgetTrendingNews = ({ dark, data }) => {
    return (
        <div className="trending_widget mb30">
            <h2 className="widget-title">Trending News</h2>
            <div className="single_post post_type3">
                <div className="post_img">
                    <div className="img_wrap">
                        <Link to={`/post/${data?.[0]?.attributes?.title}/${data?.[0]?.id}`} >
                            <img style={{width:350,height:250}} src={data?.[0]?.attributes?.url} alt="trendbig1" />
                        </Link>
                    </div>
                    {/* <span className="tranding"><FontAwesome name="bolt" /></span> */}
                </div>
                <div className="single_post_text">
                    <div className="meta3"><Link to={`/category/${data?.[0]?.attributes?.categoryTitle}/${data?.[0]?.attributes?.category_id}`} >{data?.[0]?.attributes?.categoryTitle}</Link>
                        <Link to="/">{formatDate(data?.[0]?.attributes?.publishedOn ? data?.[0]?.attributes?.publishedOn : data?.[0]?.attributes?.createdAt)} - {data?.[0]?.attributes?.readingTime}</Link>
                    </div>
                    <h4><Link to={`/post/${data?.[0]?.attributes?.title}/${data?.[0]?.id}`} >{data?.[0]?.attributes?.title}</Link></h4>
                    <div className="space-10" />
                    <p className="post-p">{data?.[0]?.attributes?.content && markdownToTxt(data?.[0]?.attributes?.content)}</p>
                </div>
            </div>

            {data && data?.slice(2, 5).map((item, i) => (
                <Fragment key={i}>
                    <div className="space-15" />
                    {dark ? <div className="border_white" /> : <div className="border_black" />}
                    <div className="space-30" />
                    <div className="single_post widgets_small">
                        <div className="post_img">
                            <div className="img_wrap">
                            <Link to={`/post/${item?.attributes?.title?.replaceAll(" ","-")}/${item?.id}`}>
                                <img style={{width:100,height:77}} src={item?.attributes?.url} alt="thumb" />
                                </Link>
                            </div>
                            {/* <span className="tranding"><FontAwesome name="bolt" /></span> */}
                        </div>
                        <div className="single_post_text">
                            <div className="meta2"><Link to={`/category/${item?.attributes?.categoryTitle?.replaceAll(" ","-")}/${item?.attributes?.category_id}`} >{item?.attributes?.categoryTitle}</Link>
                                <Link to="/">{formatDate(item?.attributes?.publishedOn ? item?.attributes?.publishedOn : item?.attributes?.createdAt)}</Link>
                            </div>
                            <h4><Link to={`/post/${item?.attributes?.title?.replaceAll(" ","-")}/${item?.id}`} className="post-ellipse" >{item?.attributes?.title}</Link></h4>
                        </div>
                    </div>
                </Fragment>
            ))}
        </div>
    );
};

export default WidgetTrendingNews;
import React, { useState } from 'react';
import FontAwesome from "../uiStyle/FontAwesome";
import { Link } from "react-router-dom";
import Swiper from 'react-id-swiper';
import ModalVideo from 'react-modal-video'

import black_white1 from '../../doc/img/bg/black_white1.jpg';
import black_white2 from '../../doc/img/bg/black_white2.jpg';
import { formatDate } from '../../utils/commonFunctions';

const mixArray = [
    {
        icon: 'play',
        image: black_white1,
        category: 'TECHNOLOGY',
        date: 'March 26, 2020',
        title: 'Success is not a good food failure makes you humble',
    },
    {
        icon: 'bolt',
        image: black_white2,
        category: 'TECHNOLOGY',
        date: 'March 26, 2020',
        title: 'Success is not a good food failure makes you humble',
    },
    {
        icon: 'play',
        image: black_white1,
        category: 'TECHNOLOGY',
        date: 'March 26, 2020',
        title: 'Success is not a good food failure makes you humble',
    },
    {
        icon: 'bolt',
        image: black_white2,
        category: 'TECHNOLOGY',
        date: 'March 26, 2020',
        title: 'Success is not a good food failure makes you humble',
    },
];

const MixCarousel = ({ className, dark, data }) => {
    const [swiper, setSwiper] = useState(null);
    const [vModal, setvModal] = useState(false);
    const [videoId] = useState('0r6C3z3TEKw');

    const goNext = () => {
        if (swiper !== null) {
            swiper.slideNext();
        }
    };

    const goPrev = () => {
        if (swiper !== null) {
            swiper.slidePrev();
        }
    };
    const params = {
        slidesPerView: 2,
        spaceBetween: 30,
        loop: true,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
        },
        breakpoints: {
            1024: {
                slidesPerView: 2,
                spaceBetween: 30
            },
            768: {
                slidesPerView: 1,
                spaceBetween: 0
            },
            300: {
                slidesPerView: 1,
                spaceBetween: 0
            },
        },
        autoplay: {
            disableOnInteraction: true
        },
        autoplaySpeed : 6000
    };
    return (
        <div className={`mix_area ${className ? className : ''}`}>
            <div className="space-30" />
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className={`mix_carousel ${dark ? 'primay_bg' : ''}`}>
                            {/*CAROUSEL START*/}
                            <div className="single_mix_carousel nav_style3">
                                {data?.length > 0 &&
                                    <Swiper getSwiper={setSwiper} {...params}>
                                        {data?.slice(5, 8)?.map((item, i) => (
                                            <Link className="play_btn" to={`/post/${item?.attributes?.title?.replaceAll(" ","-")}/${item?.id}`}>
                                                <div key={i} className="single_post post_type6 post_type9">
                                                    <div className="post_img gradient1">
                                                        <div className="img_wrap">

                                                            <img style={{width:540,height:420}} src={item?.attributes?.url} alt="news" />

                                                        </div>
                                                        {/* <span onClick={() => setvModal(true)}
                                                            className={`tranding ${i % 2 ? 'left' : ''}`}><FontAwesome
                                                                name={`${i % 2 ? 'play' : 'bolt'}`} /></span> */}
                                                    </div>
                                                    <div className="single_post_text">
                                                        <div className="meta"><Link to={`/category/${item?.attributes?.categoryTitle?.replaceAll(" ","-")}/${item?.attributes?.category_id}`}>{item?.attributes?.categoryTitle}</Link>
                                                            <Link to="#">{formatDate(item?.attributes?.publishedOn ? item?.attributes?.publishedOn : item?.attributes?.createdAt)} - {item?.attributes?.readingTime}</Link>
                                                        </div>
                                                        <h4><Link className="post-ellipse-3" to={`/post/${item?.attributes?.title?.replaceAll(" ","-")}/${item?.id}`}>{item?.attributes?.title}</Link></h4>
                                                    </div>
                                                </div>
                                            </Link>
                                        ))}
                                    </Swiper>}
                                <div className="owl-nav">
                                    <div onClick={goPrev} className="owl-prev"><FontAwesome name="angle-left" /></div>
                                    <div onClick={goNext} className="owl-next"><FontAwesome name="angle-right" /></div>
                                </div>
                            </div>
                        </div>
                        {/*CAROUSEL END*/}
                    </div>
                </div>
            </div>
            <div className="space-30" />
            <ModalVideo channel='youtube' isOpen={vModal} videoId={videoId}
                onClose={() => setvModal(false)} />
        </div>
    );
};

export default MixCarousel;
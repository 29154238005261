import React, { useState, useEffect, useRef } from 'react';
import { Link } from "react-router-dom";
import FooterCopyright from "../FooterCopyright";
import FontAwesome from "../uiStyle/FontAwesome";
import { Modal, ModalBody } from 'reactstrap';
import flogo from '../../assets/img/logo/logo.png';
import { addNewsLetterAction, resetArticleReducer, addNewsLetterEmailAction } from '../../store/actions';
import { connect } from 'react-redux';
import NewsLetter from '../../components/NewsLetter';
import { toast } from "react-toastify";
import SimpleReactValidator from 'simple-react-validator';

const FooterArea = ({ newsLetterAdded, addNewsLetterAction, resetArticleReducer, className, newsLetterEmailAdded, addNewsLetterEmailAction }) => {
    const simpleValidator = useRef(new SimpleReactValidator())
    const [modal, setModal] = useState(false);
    const [email, setEmail] = useState('');
    const [popupModal, setPopupModal] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
        setTimeOutNewsLetter()
    }, [])

    useEffect(() => {
        if (newsLetterAdded) {
            toast.success("Submitted Successfully")
            toggle()
            resetArticleReducer()
        }
        if (newsLetterEmailAdded) {
            toast.success("Subscribed to Newsletter")
            setEmail('')
            setPopupModal(false)
            resetArticleReducer()
        }
    }, [newsLetterAdded, newsLetterEmailAdded])

    const setTimeOutNewsLetter = () => {
        setTimeout(() => {
            setPopupModal(true)
        }, 1000 * 11);
    }

    const submitHandler = e => {
        e.preventDefault();
        if (simpleValidator.current.allValid()) {
            addNewsLetterEmailAction({ data: { email: email } })
        } else {
            toast.error('Please fill the input');
            simpleValidator.current.showMessages();
        }
    };

    const toggle = () => setModal(!modal);

    const handleAddData = (data) => {
        addNewsLetterAction(data)
    }

    return (
        <div className={`footer footer_area1 ${className ? className : ''}`}>
            <div className="container">
                <div className="cta">
                    <div className="row">
                        <div className="col-md-6 align-self-center">
                            <div className="">
                                <Link to="/">
                                    <img src={flogo} alt="logo" className='footer_logo_tint' />
                                </Link>
                            </div>
                            <div className="social2">
                                <ul className="inline">
                                    <li><a target="_blank" href="https://twitter.com/StoriesOfRepute"><FontAwesome name="twitter" /></a></li>
                                    <li><a target="_blank" href="https://www.facebook.com/StoriesOfRepute"><FontAwesome name="facebook-f" /></a></li>
                                    <li><a target="_blank" href="https://www.youtube.com/@StoriesOfRepute"><FontAwesome name="youtube-play" /></a></li>
                                    <li><a target="_blank" href="https://www.instagram.com/storiesofrepute"><FontAwesome name="instagram" /></a></li>
                                    <li><a target="_blank" href="https://www.linkedin.com/company/the-repute"><FontAwesome name="linkedin" /></a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 offset-lg-2 mt-5">

                            <NewsLetter title={"Subscribe to our Newsletter"} field_type="partnership" modal={modal} handleAddData={handleAddData} handleClick={toggle} customButton={<>   <div className="signup_form d-flex  align-items-center">
                                <img className="newsletter_img" src={require("../../assets/img/icons/newsletter.png")} />
                                <h3 className='newsletter_popup_title'>Newsletter</h3>
                            </div>
                                <div className="signup_form d-flex">
                                    <div className='subscribe-text'>Subscribe to our newsletter and stay informed. <br />Click the button below to get started.</div>

                                </div>
                                <div className='signup_form mt-2'>
                                    <form onSubmit={submitHandler}>
                                        <input onChange={e => setEmail(e.target.value)} onBlur={() => simpleValidator.current.showMessageFor('name')} value={email} className="signup"
                                            type="email" placeholder="Your email address" />
                                        <button type="submit" className="cbtn">subscribe</button>
                                    </form>
                                </div>
                                {simpleValidator.current.message('email', email, 'required|email')}
                            </>} />

                        </div>
                    </div>
                </div>
                {/* <div className="border_white"/>
                <div className="space-40"/> */}
                {/* <div className="row justify-content-center">
                    <div className="col-lg-8">
                        <div className="row">
                            <div className="col-sm-6 col-lg">
                                <div className="single_footer_nav border_white_right">
                                    <FooterNewsCategories/>
                                </div>
                            </div>
                            <div className="col-sm-6 col-lg">
                                <div className="single_footer_nav">
                                    <h3 className="widget-title2">Living</h3>
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <ul>
                                                <li><Link to="/">Crossword</Link>
                                                </li>
                                                <li><Link to="/">Food</Link>
                                                </li>
                                                <li><Link to="/">Automobiles</Link>
                                                </li>
                                                <li><Link to="/">Education</Link>
                                                </li>
                                                <li><Link to="/">Health</Link>
                                                </li>
                                                <li><Link to="/">Magazine</Link>
                                                </li>
                                                <li><Link to="/">Weddings</Link>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="col-lg-6">
                                            <ul>
                                                <li><Link to="/">Classifieds</Link>
                                                </li>
                                                <li><Link to="/">Photographies</Link>
                                                </li>
                                                <li><Link to="/">NYT Store</Link>
                                                </li>
                                                <li><Link to="/">Journalisms</Link>
                                                </li>
                                                <li><Link to="/">Public Editor</Link>
                                                </li>
                                                <li><Link to="/">Tools & Services</Link>
                                                </li>
                                                <li><Link to="/">My Account</Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="space-40"/>
                        <div className="border_white"/>
                        <div className="space-40"/>
                        <div className="row">
                            <div className="col-sm-6 col-lg-5">
                                <div className="single_footer_nav border_white_right">
                                    <h3 className="widget-title2">Opinion</h3>
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <ul>
                                                <li><Link to="/">Today’s Opinion</Link>
                                                </li>
                                                <li><Link to="/">Op-Ed Contributing</Link>
                                                </li>
                                                <li><Link to="/">Contributing Writers</Link>
                                                </li>
                                                <li><Link to="/">Business News</Link>
                                                </li>
                                                <li><Link to="/">Collections</Link>
                                                </li>
                                                <li><Link to="/">Today’s Paper</Link>
                                                </li>
                                                <li><Link to="/">Saturday Review</Link>
                                                </li>
                                                <li><Link to="/">Product Review</Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-lg-7">
                                <TwitterFeed/>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <FooterMoreNews/>
                    </div>
                </div> */}
            </div>
            <FooterCopyright />
            <Modal isOpen={popupModal} fade={false} className="newsletter_popup_modal" backdropClassName="newsletter_popup_modal_backdrop" >
                <ModalBody className="modal_newsletter_content" style={{ padding: 20 }}>
                    <div className='d-flex justify-content-end'>
                        <FontAwesome name="close" style={{ color: 'white', cursor: 'pointer', marginBottom: -6 }} onClick={() => setPopupModal(false)} />
                    </div>
                    <div className="d-flex  align-items-center justify-content-center">
                        <img src={require("../../assets/img/logo/logo.png")} alt="logo" className='footer_logo_tint' />
                    </div>
                    <div className="d-flex justify-content-center">
                        <div className='subscribe-text'> Subscribe to our newsletter and stay informed.<br />Click the button below to get started.</div>
                    </div>
                    <div className='mt-2'>
                        <form onSubmit={submitHandler}>
                            <input onChange={e => setEmail(e.target.value)} onBlur={() => simpleValidator.current.showMessageFor('name')} value={email} className="signup"
                                type="email" placeholder="Your email address" />
                            <button type="submit" className="newsletter_popup_btn mt-3">Subscribe</button>
                        </form>
                    </div>
                    {simpleValidator.current.message('email', email, 'required|email')}
                </ModalBody>
            </Modal>
        </div>
    );
};

const mapStateToProps = ({ articlesReducer }) => {
    const { newsLetterAdded, newsLetterEmailAdded } = articlesReducer
    return { newsLetterAdded, newsLetterEmailAdded }
}

const mapActionsToProps = {
    addNewsLetterAction,
    resetArticleReducer,
    addNewsLetterEmailAction
}

export default connect(mapStateToProps, mapActionsToProps)(FooterArea)
import React from 'react';
import { Link } from "react-router-dom";
// import logo from '../../doc/img/logo/logo.png';
import logo from '../../assets/img/logo/logo.png';
import logoDark from '../../doc/img/logo/footer_logo.png';
import tp_banner from '../../doc/img/bg/banner1.png';


const LogoArea = ({ className, dark }) => {
    return (
        <div className={`logo_area ${className ? className : ''}`}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 d-flex justify-content-center mt-3">
                        <div className="">
                            <Link to="/">
                                <img src={logo} alt="logo" style={{height:95}} />
                            </Link>
                        </div>
                    </div>
                    {/* <div className="col-lg-8 align-self-center">
                        <div className="banner1" style={{height:90}}>
                            <Link to="#">
                                <img src={tp_banner} alt="banner"/>
                            </Link>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    );
};

export default LogoArea;
import React, { useState } from 'react';
import FontAwesome from "../uiStyle/FontAwesome";
import { Link } from "react-router-dom";
import Swiper from 'react-id-swiper';

import trendbig1 from "../../doc/img/trending/trendbig1.jpg";
import trendbig2 from "../../doc/img/trending/trendbig2.jpg";
import markdownToTxt from 'markdown-to-txt';

const trendingNews = [
    {
        category: 'TECHNOLOGY',
        date: 'March 26, 2020',
        title: 'There may be no consoles in the future ea exec says',
        body: 'The property, complete with 30-seat screening from room, a 100-seat amphitheater and a swimming pond with sandy shower…',
        image: trendbig1,
    },
    {
        category: 'TECHNOLOGY',
        date: 'March 26, 2020',
        title: 'Japan’s virus success has puzzled the world. Is its luck running out?',
        body: 'The property, complete with 30-seat screening from room, a 100-seat amphitheater and a swimming pond with sandy shower…',
        image: trendbig2,
    },
    {
        category: 'TECHNOLOGY',
        date: 'March 26, 2020',
        title: 'There may be no consoles in the future ea exec says',
        body: 'The property, complete with 30-seat screening from room, a 100-seat amphitheater and a swimming pond with sandy shower…',
        image: trendbig1,
    },
];

function formatDate(dateStr) {
    const date = new Date(dateStr);
    const formattedDate = date.toLocaleDateString("en-US", { month: "long", day: "numeric", year: "numeric" });
    return formattedDate;
}

const TrendingNewsSlider = ({ data }) => {
    const [swiper, setSwiper] = useState(null);

    const goNext = () => {
        if (swiper !== null) {
            swiper.slideNext();
        }
    };

    const goPrev = () => {
        if (swiper !== null) {
            swiper.slidePrev();
        }
    };
    const params = {
        slidesPerView: 2,
        spaceBetween: 20,
        loop: true,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
        },
        breakpoints: {
            1024: {
                slidesPerView: 2,
                spaceBetween: 20
            },
            768: {
                slidesPerView: 1,
                spaceBetween: 20
            },
            300: {
                slidesPerView: 1,
                spaceBetween: 20
            },
        }
    };
    return (
        <div className="carousel_post2_type3 nav_style1">
            {data?.length > 0 && <Swiper getSwiper={setSwiper} className="trancarousel" {...params}>
                {data?.map((item, i) => (
                    <div key={i} className="single_post post_type3">
                        <div className="post_img">
                            <div className="img_wrap">
                                <Link to={`/post/${item?.attributes?.title?.replaceAll(" ","-")}/${item?.id}`}> <img style={{width:355,height:254}} src={item?.attributes?.url} alt="thumb" /></Link>
                            </div>
                            {/* <span className="tranding"><FontAwesome name="fa-bolt" /></span> */}
                        </div>
                        <div className="single_post_text">
                            <div className="meta3"><Link to={`/category/${item?.attributes?.categoryTitle?.replaceAll(" ","-")}/${item?.attributes?.category_id}`} >{item?.attributes?.categoryTitle || ""}</Link>
                                <Link to="/">{formatDate(item?.attributes?.publishedOn ? item?.attributes?.publishedOn : item?.attributes?.createdAt)}</Link>
                            </div>
                            <h4 ><Link to={`/post/${item?.attributes?.title?.replaceAll(" ","-")}/${item?.id}`} className='post-ellipse'>{item.attributes?.title}</Link></h4>
                            <div className="space-10" />
                            <p className="post-p">{item.attributes?.content && markdownToTxt(item.attributes?.content)}</p>
                        </div>
                    </div>
                ))}
            </Swiper>}
            <div className="navBtns">
                <div onClick={goPrev} className="navBtn prevtBtn"><FontAwesome name="angle-left" /></div>
                <div onClick={goNext} className="navBtn nextBtn"><FontAwesome name="angle-right" /></div>
            </div>
        </div>
    );
};

export default TrendingNewsSlider;
import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import Swiper from 'react-id-swiper';
import FontAwesome from "../uiStyle/FontAwesome";
import { getFormattedDate } from '../../utils/commonFunctions';
import { connect } from 'react-redux';
import { loadTrendingCategoriesAction } from '../../store/actions';

const TopBar = ({ className, dark, categories, loadTrendingCategoriesAction, trendingTopics }) => {
    const [swiper, setSwiper] = useState(null);

    useEffect(() => {
        if (categories.length) {
            loadTrendingCategoriesAction(categories.find((e) => e.attributes.title === "Trending stories")?.id)
        }
    }, [categories])

    const goNext = () => {
        if (swiper !== null) {
            swiper.slideNext();
        }
    };

    const goPrev = () => {
        if (swiper !== null) {
            swiper.slidePrev();
        }
    };
    const params = {
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
        },
        autoplay: {
            delay: 2500,
            disableOnInteraction: false
        },
    };

    return (
        <div className={`topbar ${className ? className : ''}`} id="top">
            <div className="container">
                <div className="row">
                    <div className="col-md-8 align-self-center d-none d-sm-block">
                        <div className={`trancarousel_area ${dark ? 'white' : ''}`}>
                            <p className="trand">Trending</p>
                            <div className="nav_style1">
                                {trendingTopics.length > 0 && <Swiper getSwiper={setSwiper} className="trancarousel" {...params}>
                                    {trendingTopics?.map((item) => (
                                        <div className="trancarousel_item">
                                            <p><Link to={`/post/${item?.attributes?.title?.replaceAll(" ", "-")}/${item?.id}`}>{item.attributes.title}</Link>
                                            </p>
                                        </div>
                                    ))}
                                </Swiper>}
                                <div className="navBtns">
                                    <button className="navBtn prevBtn" onClick={goPrev}><FontAwesome name="angle-left" />
                                    </button>
                                    <button className="navBtn nextBtn" onClick={goNext}><FontAwesome
                                        name="angle-right" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 align-self-center">
                        <div className="top_date_social text-right">
                            <div className={`paper_date ${dark ? 'white' : ''}`}>
                                <p>{getFormattedDate()}</p>
                            </div>
                            <div className={`social1 ${dark ? 'white' : ''}`}>
                                <ul className="inline">
                                    <li><a target="_blank" href="https://twitter.com/StoriesOfRepute"><FontAwesome name="twitter" /></a></li>
                                    <li><a target="_blank" href="https://www.facebook.com/StoriesOfRepute"><FontAwesome name="facebook-f" /></a></li>
                                    <li><a target="_blank" href="https://www.youtube.com/@StoriesOfRepute"><FontAwesome name="youtube-play" /></a></li>
                                    <li><a target="_blank" href="https://www.instagram.com/storiesofrepute"><FontAwesome name="instagram" /></a></li>
                                    <li><a target="_blank" href="https://www.linkedin.com/company/the-repute"><FontAwesome name="linkedin" /></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                  
                </div>
            </div>
            <div className="align-self-center d-sm-none">
                        <div className={`trancarousel_area ${dark ? 'white' : ''}`}>
                            <p className="trand">Trending</p>
                            <div className="nav_style1">
                                {trendingTopics.length > 0 && <Swiper getSwiper={setSwiper} className="trancarousel" {...params}>
                                    {trendingTopics?.map((item) => (
                                        <div className="trancarousel_item">
                                            <p><Link to={`/post/${item?.attributes?.title?.replaceAll(" ", "-")}/${item?.id}`}>{item.attributes.title}</Link>
                                            </p>
                                        </div>
                                    ))}
                                </Swiper>}
                                <div className="navBtns">
                                    <button className="navBtn prevBtn" onClick={goPrev}><FontAwesome name="angle-left" />
                                    </button>
                                    <button className="navBtn nextBtn" onClick={goNext}><FontAwesome
                                        name="angle-right" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
        </div>
    );
};


const mapStateToProps = ({ articlesReducer }) => {
    const { categories, trendingTopics } = articlesReducer
    return { categories, trendingTopics }
}


const mapActionsToProps = {
    loadTrendingCategoriesAction
}

export default connect(mapStateToProps, mapActionsToProps)(TopBar);
import axios from "axios";

const BASE_URL = "https://admin.therepute.in"

export const getLocation = () => {
    return new Promise((resolve, reject) => {
        axios.get("https://api.bigdatacloud.net/data/reverse-geocode-client")
            .then((res) => resolve(res))
            .catch((err) => reject(err))
    })
} 

export const getWeatherInfo = (data) => {
    return new Promise((resolve, reject) => {
        axios.get(`https://api.weatherapi.com/v1/current.json?key=776b31111285468c92d115953231007&q=${data}&aqi=no`)
            .then((res) => resolve(res))
            .catch((err) => reject(err))
    })
} 

export const loadArticles = () => {
    return new Promise((resolve, reject) => {
        axios.get(`${BASE_URL}/api/articles`)
            .then((res) => resolve(res))
            .catch((err) => reject(err))
    })
}

export const loadArticlesById = (id) => {
    return new Promise((resolve, reject) => {
        axios.get(`${BASE_URL}/api/articles/${id}`)
            .then((res) => resolve(res))
            .catch((err) => reject(err))
    })
} 

export const loadCategories = () => {
    return new Promise((resolve, reject) => {
        axios.get(`${BASE_URL}/api/categories`)
            .then((res) => resolve(res))
            .catch((err) => reject(err))
    })
} 

export const addSubscription = (data) => {
    return new Promise((resolve, reject) => {
        axios.post(`${BASE_URL}/api/subscriptions`,data)
            .then((res) => resolve(res))
            .catch((err) => reject(err))
    })
} 

export const addContactUs = (data) => {
    return new Promise((resolve, reject) => {
        axios.post(`${BASE_URL}/api/contact-uses`,data)
            .then((res) => resolve(res))
            .catch((err) => reject(err))
    })
} 

export const loadCategoryById = (id) => {
    return new Promise((resolve, reject) => {
        axios.get(`${BASE_URL}/api/articles-by-category/${id}`)
            .then((res) => resolve(res))
            .catch((err) => reject(err))
    })
} 

export const addNewsLetterEmail = (data) => {
    return new Promise((resolve, reject) => {
        axios.post(`${BASE_URL}/api/newsletters`,data)
            .then((res) => resolve(res))
            .catch((err) => reject(err))
    })
} 
export const DEFAULT = 'DEFAULT';
export const THROW_ERROR = 'THROW_ERROR';
export const THROW_SUCCESS = 'THROW_SUCCESS';
export const IS_LOADING = 'IS_LOADING';
export const RE_RENDERED = 'RE_RENDERED';

export const LOAD_LOCATION_DATA = 'LOAD_LOCATION_DATA';
export const LOAD_LOCATION_SUCCESS = 'LOAD_LOCATION_SUCCESS';
export const LOAD_LOCATION_FAILED = 'LOAD_LOCATION_FAILED';

export const LOAD_WEATHER_DATA = 'LOAD_WEATHER_DATA';
export const LOAD_WEATHER_SUCCESS = 'LOAD_WEATHER_SUCCESS';
export const LOAD_WEATHER_FAILED = 'LOAD_WEATHER_FAILED';

export const LOAD_ARTICLES_DATA = 'LOAD_ARTICLES_DATA';
export const LOAD_ARTICLES_SUCCESS = 'LOAD_ARTICLES_SUCCESS';
export const LOAD_ARTICLES_FAILED = 'LOAD_ARTICLES_FAILED';

export const LOAD_CATEGORIES_DATA = 'LOAD_CATEGORIES_DATA';
export const LOAD_CATEGORIES_SUCCESS = 'LOAD_CATEGORIES_SUCCESS';
export const LOAD_CATEGORIES_FAILED = 'LOAD_CATEGORIES_FAILED';

export const LOAD_ARTICLES_BY_ID_DATA = 'LOAD_ARTICLES_BY_ID_DATA';
export const LOAD_ARTICLES_BY_ID_SUCCESS = 'LOAD_ARTICLES_BY_ID_SUCCESS';
export const LOAD_ARTICLES_BY_ID_FAILED = 'LOAD_ARTICLES_BY_ID_FAILED';

export const ADD_SUBSCRIPTION_DATA = 'ADD_SUBSCRIPTION_DATA';
export const ADD_SUBSCRIPTION_SUCCESS = 'ADD_SUBSCRIPTION_SUCCESS';
export const ADD_SUBSCRIPTION_FAILED = 'ADD_SUBSCRIPTION_FAILED';

export const ADD_CONTACT_DATA = 'ADD_CONTACT_DATA';
export const ADD_CONTACT_SUCCESS = 'ADD_CONTACT_SUCCESS';
export const ADD_CONTACT_FAILED = 'ADD_CONTACT_FAILED';

export const LOAD_TRENDING_CATEGORIES_DATA = 'LOAD_TRENDING_CATEGORIES_DATA';
export const LOAD_TRENDING_CATEGORIES_SUCCESS = 'LOAD_TRENDING_CATEGORIES_SUCCESS';
export const LOAD_TRENDING_CATEGORIES_FAILED = 'LOAD_TRENDING_CATEGORIES_FAILED';

export const ADD_NEWSLETTER_DATA = 'ADD_NEWSLETTER_DATA';
export const ADD_NEWSLETTER_SUCCESS = 'ADD_NEWSLETTER_SUCCESS';
export const ADD_NEWSLETTER_FAILED = 'ADD_NEWSLETTER_FAILED';

export const ADD_NEWSLETTER_EMAIL_DATA = 'ADD_NEWSLETTER_EMAIL_DATA';
export const ADD_NEWSLETTER_EMAIL_SUCCESS = 'ADD_NEWSLETTER_EMAIL_SUCCESS';
export const ADD_NEWSLETTER_EMAIL_FAILED = 'ADD_NEWSLETTER_EMAIL_FAILED';

export const RESET_ARTICLES_REDUCER_STATE = 'RESET_ARTICLES_REDUCER_STATE';



import React, { Fragment, useEffect } from 'react';
import BreadCrumb from "../../components/BreadCrumb";
import BusinessNews from "../../components/BusinessNews";
import FontAwesome from "../../components/uiStyle/FontAwesome";
import { Link } from "react-router-dom";
import WidgetTab from "../../components/WidgetTab";
import WidgetTrendingNews from "../../components/WidgetTrendingNews";
import NewsLetter from "../../components/NewsLetter";
import FollowUs from "../../components/FollowUs";

// images
import business1 from '../../doc/img/business/business1.jpg';
import business2 from '../../doc/img/business/business2.jpg';
import business3 from '../../doc/img/business/business3.jpg';
import banner2 from "../../doc/img/bg/sidebar-1.png";
import union from '../../doc/img/icon/union.png';
import BannerSection from "../../components/BannerSection";
import { useParams } from "react-router-dom";

import { loadArticlesAction, loadCategoriesAction } from '../../store/actions';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';


const AllCategories = ({ loadArticlesAction, loadCategoriesAction, articles, categories }) => {

    useEffect(() => {
        window.scrollTo(0, 0);
        loadCategories()
    }, [])

    const loadArticles = () => {
        loadArticlesAction()
    }

    const loadCategories = () => {
        loadCategoriesAction()
    }


    return (
        <Fragment>
             <Helmet>
                <title>Repute - Categories</title>
                <meta name="description" content="Repute is a business media firm established to bring together entities of repute." />
            </Helmet>
            <BreadCrumb title={'Categories'} />
            <div className="archives padding-top-30">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-lg-12">
                            <div className="businerss_news">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="widget category mb30">
                                            <div class="container text-center">
                                                <ul>
                                                    <div class="row">
                                                        {categories?.filter((e)=> e.attributes.title !== "Trending stories").map((item, i) => (
                                                            <div class="col-md-4 col-sm-12 mb-4">
                                                                <li key={i} className=''>
                                                                    <Link to={`/category/${item?.attributes?.title?.replaceAll(" ","-")}/${item?.id}`} style={{ background: `url(${require(`../../assets/img/categories/category${i % 2 ? '1' : '2'}.jpg`)}` }}> <span>{item?.attributes?.title}</span>
                                                                        <img src={union} alt="category" />
                                                                    </Link>
                                                                </li>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </ul>
                                            </div>
                                            <ul>

                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="space-70" />
        </Fragment>
    );
};



const mapStateToProps = ({ articlesReducer }) => {
    const { articles, categories } = articlesReducer
    return { articles, categories }
}


const mapActionsToProps = {
    loadArticlesAction,
    loadCategoriesAction
}

export default connect(mapStateToProps, mapActionsToProps)(AllCategories)
import React, { Fragment, Component } from 'react';
import FontAwesome from "../../components/uiStyle/FontAwesome";
import NewsLetter from "../../components/NewsLetter";
import SimpleReactValidator from 'simple-react-validator';
import { toast } from "react-toastify";
import BreadCrumb from '../../components/BreadCrumb';
import { addSubscriptionAction, resetArticleReducer, addContactUsAction } from '../../store/actions';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

const options = ["Improve Brand Visibility", "Gain Repute", "Improve Business Opportunities", "Collaborate with Repute", "Earn Speaking Engagements", "Create promotional content"]

class ContactUsPage extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
    }

    state = {
        fullName: '',
        subject: '',
        email: '',
        phoneNumber: '',
        message: '',
        modal: false
    };

    componentDidMount = () => {
        window.scrollTo(0, 0);
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps?.subscriptionAdded !== this.props.subscriptionAdded) {
            if (this.props.subscriptionAdded) {
                toast.success("Submitted Successfully")
                this.toggle()
                this.props.resetArticleReducer()
            }
        }
        if (prevProps?.contactUsAdded !== this.props.contactUsAdded) {
            if (this.props.contactUsAdded) {
                this.setState({
                    fullName: '',
                    subject: '',
                    email: '',
                    phoneNumber: '',
                    message: '',
                });
                this.validator.hideMessages()
                this.props.resetArticleReducer()
                toast.success("Submitted Successfully")
            }
        }
    }

    changeHandler = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    };
    submitHandler = e => {
        e.preventDefault();
        if (this.validator.allValid()) {
            // toast.success('You submitted the form and stuff!')
            this.props.addContactUsAction({ data: this.state })
        } else {
            toast.error('Please fill the input');
            this.validator.showMessages();
            // rerender to show messages for the first time
            // you can use the autoForceUpdate option to do this automatically`
            this.forceUpdate();
        }
    };

    toggle = () => this.setState({ modal: !this.state.modal });

    handleAddData = (data) => {
        this.props.addSubscriptionAction(data)
    }

    render() {
        const { fullName, subject, email, phoneNumber, message, modal } = this.state;
        return (
            <Fragment>
                <Helmet>
                    <title>Repute - Contact Us</title>
                    <meta name="description" content="Repute is a business media firm established to bring together entities of repute." />
                </Helmet>
                {/* <div className="inner inner_bg inner_overlay">
                    <div className="container">
                        <div className="inner_wrap">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="title_inner">
                                        <h6>CONTACT US</h6>
                                        <h1>let's Connect</h1>
                                    </div>
                                </div>
                            </div>
                            <div className="inner_scroll">
                                <div className="scrollIcon">
                                    <img src={scrollIcon} alt="scrollIcon" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                <BreadCrumb className="shadow5" title="Contact Us" />
                <div className="contacts mt-3">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4">
                                <div className="box single_contact_box">
                                    <div className="contact_title">
                                        <h3>Headquarters</h3>
                                    </div>
                                    <div className="contact_details">
                                        <div className="contact_details_icon">
                                            <FontAwesome name="map-marker-alt" />
                                        </div>
                                        <p>LOCATION:</p>
                                        <h6>REPUTE BUSINESS SOLUTIONS <br /> WeWork Prestige Central, 36, Infantry Road, Bangalore – 560001
                                        </h6>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-8">
                                <div className="map">
                                    <iframe
                                        title="map"
                                        frameBorder={0}
                                        height="450px"
                                        width="100%"
                                        src="https://maps.google.com/maps?q=Bangalore&t=m&z=17&output=embed&iwloc=near"
                                        allowFullScreen />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*contact form area*/}
                <div className="contact_form padding-bottom">
                    <div className="container">
                        {/* <div className="row">
                            <div className="col-12">
                                <div className="map">
                                    <iframe
                                        title="map"
                                        frameBorder={0}
                                        height="450px"
                                        width="100%"
                                        src="https://maps.google.com/maps?q=Bangalore&t=m&z=17&output=embed&iwloc=near"
                                        allowFullScreen />
                                </div>
                            </div>
                        </div> */}
                        <div className="space-50" />
                        <div className="row">
                            <div className="col-lg-8">
                                <div className="cotact_form">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className='text-center contact_form_title'>Get In Touch</div>
                                            <div className='d-flex justify-content-center align-items-center'>
                                                <div className='text-center contact_form_sub_title'>Fill out the form below and we will contact you as soon as possible!</div></div>
                                        </div>
                                        <div className="col-12">
                                            <form onSubmit={this.submitHandler}>
                                                <div className="row">
                                                    <div className="col-lg-6 mb-4">
                                                        <input name="fullName" value={fullName} onChange={this.changeHandler}
                                                            type="text"
                                                            placeholder="Your Name" />
                                                        {this.validator.message('Full Name', fullName, 'required')}
                                                    </div>
                                                    <div className="col-lg-6 mb-4">
                                                        <input name="email" value={email}
                                                            onChange={this.changeHandler} type="email"
                                                            placeholder="Your Email" />
                                                        {this.validator.message('Email', email, 'required|email')}
                                                    </div>
                                                    <div className="col-lg-6 mb-4">
                                                        <input name="phoneNumber" value={phoneNumber} onChange={this.changeHandler}
                                                            type="number"
                                                            placeholder="Phone Number" />
                                                        {this.validator.message('Phone Number', phoneNumber, 'required')}
                                                    </div>
                                                    <div className="col-lg-6 mb-4">
                                                        <select name="subject" value={subject} onChange={this.changeHandler} defaultValue="" class="form-select" >
                                                            <option disabled value="">Get In Touch</option>
                                                            {options?.map((value) => (
                                                                <option value={value}>{value}</option>
                                                            ))}
                                                        </select>
                                                        {this.validator.message('Get In Touch', subject, 'required')}
                                                    </div>
                                                    <div className="col-12 mb-4">
                                                        <textarea name="message"
                                                            value={message}
                                                            onChange={this.changeHandler}
                                                            id="message"
                                                            cols="30" rows="5"
                                                            placeholder="Message" />
                                                        {this.validator.message('Message', message, 'required')}
                                                    </div>
                                                    <div className="col-12 d-flex justify-content-center align-items-center">
                                                        <div className="space-20" />
                                                        <button className="cbtn1" type="submit">Send</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                {/* <FollowUs title="Follow Us" /> */}
                                <NewsLetter field_type="subscription" title={"BECOME A SUBSCRIBER"} subTitle={"Gain access to the best of information that is both insightful and informed, to help empower you with the region’s business and economic landscape."} modal={modal} handleAddData={this.handleAddData} handleClick={this.toggle} />
                            </div>
                        </div>
                    </div>
                </div>
                {/* <BannerSection /> */}
            </Fragment>
        )
    }
}

const mapStateToProps = ({ articlesReducer }) => {
    const { subscriptionAdded, contactUsAdded } = articlesReducer
    return { subscriptionAdded, contactUsAdded }
}


const mapActionsToProps = {
    addSubscriptionAction,
    resetArticleReducer,
    addContactUsAction
}

export default connect(mapStateToProps, mapActionsToProps)(ContactUsPage)
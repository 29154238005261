import * as constants from "../constants";
import { getLocation, loadArticles, loadCategories,addNewsLetterEmail, getWeatherInfo, loadArticlesById, addSubscription, addContactUs, loadCategoryById } from "../../api/generalApis";

export const defaultAction = () => dispatch => {
    dispatch({
        type: constants.DEFAULT,
    })
};

export const getLocationAction = () => async (dispatch) => {
    dispatch({ type: constants.LOAD_LOCATION_DATA })
    getLocation()
        .then((res) => {
            dispatch({ type: constants.LOAD_LOCATION_SUCCESS, payload: res?.data })
        })
        .catch(() => {
            dispatch({ type: constants.LOAD_LOCATION_FAILED })
        })
}

export const getWeatherInfoAction = (data) => async (dispatch) => {
    dispatch({ type: constants.LOAD_WEATHER_DATA })
    getWeatherInfo(data)
        .then((res) => {
            dispatch({ type: constants.LOAD_WEATHER_SUCCESS, payload: res?.data })
        })
        .catch(() => {
            dispatch({ type: constants.LOAD_WEATHER_FAILED })
        })
}

export const loadArticlesAction = () => async (dispatch) => {
    dispatch({ type: constants.LOAD_ARTICLES_DATA })
    loadArticles()
        .then((res) => {
            dispatch({ type: constants.LOAD_ARTICLES_SUCCESS, payload: res?.data?.data })
        })
        .catch(() => {
            dispatch({ type: constants.LOAD_ARTICLES_FAILED })
        })
}

export const loadArticlesByIdAction = (payload) => async (dispatch) => {
    dispatch({ type: constants.LOAD_ARTICLES_BY_ID_DATA })
    loadArticlesById(payload)
        .then((res) => {
            dispatch({ type: constants.LOAD_ARTICLES_BY_ID_SUCCESS, payload: res?.data?.data })
        })
        .catch(() => {
            dispatch({ type: constants.LOAD_ARTICLES_BY_ID_FAILED })
        })
}

export const loadCategoriesAction = () => async (dispatch) => {
    dispatch({ type: constants.LOAD_CATEGORIES_DATA })
    loadCategories()
        .then((res) => {
            dispatch({ type: constants.LOAD_CATEGORIES_SUCCESS, payload: res?.data?.data })
        })
        .catch(() => {
            dispatch({ type: constants.LOAD_CATEGORIES_FAILED })
        })
}

export const addSubscriptionAction = (data) => async (dispatch) => {
    dispatch({ type: constants.ADD_SUBSCRIPTION_DATA })
    addSubscription(data)
        .then((res) => {
            dispatch({ type: constants.ADD_SUBSCRIPTION_SUCCESS, payload: res?.data?.data })
        })
        .catch(() => {
            dispatch({ type: constants.ADD_SUBSCRIPTION_FAILED })
        })
}

export const addNewsLetterAction = (data) => async (dispatch) => {
    dispatch({ type: constants.ADD_NEWSLETTER_DATA })
    addSubscription(data)
        .then((res) => {
            dispatch({ type: constants.ADD_NEWSLETTER_SUCCESS, payload: res?.data?.data })
        })
        .catch(() => {
            dispatch({ type: constants.ADD_NEWSLETTER_FAILED})
        })
}

export const addNewsLetterEmailAction = (data) => async (dispatch) => {
    dispatch({ type: constants.ADD_NEWSLETTER_EMAIL_DATA })
    addNewsLetterEmail(data)
        .then((res) => {
            dispatch({ type: constants.ADD_NEWSLETTER_EMAIL_SUCCESS, payload: res?.data?.data })
        })
        .catch(() => {
            dispatch({ type: constants.ADD_NEWSLETTER_EMAIL_FAILED})
        })
}

export const addContactUsAction = (data) => async (dispatch) => {
    dispatch({ type: constants.ADD_CONTACT_DATA })
    addContactUs(data)
        .then((res) => {
            dispatch({ type: constants.ADD_CONTACT_SUCCESS, payload: res?.data?.data })
        })
        .catch(() => {
            dispatch({ type: constants.ADD_CONTACT_FAILED })
        })
}


export const resetArticleReducer = () => async (dispatch) => {
    dispatch({ type: constants.RESET_ARTICLES_REDUCER_STATE })
}

export const loadTrendingCategoriesAction = (payload) => async (dispatch) => {
    dispatch({ type: constants.LOAD_TRENDING_CATEGORIES_DATA })
    loadCategoryById(payload)
        .then((res) => {
            dispatch({ type: constants.LOAD_TRENDING_CATEGORIES_SUCCESS, payload: res?.data?.data })
        })
        .catch(() => {
            dispatch({ type: constants.LOAD_TRENDING_CATEGORIES_FAILED })
        })
}
import React from 'react';
import {Link} from "react-router-dom";
import { formatDate } from '../../utils/commonFunctions';
import markdownToTxt from 'markdown-to-txt';

const BusinessNews = ({data, headerHide}) => {
    return (
        <div className="row">
            <div className="col-12">
                <div className="businerss_news">
                    {headerHide ? '' :
                        <div className="row">
                            <div className="col-6 align-self-center">
                                <h2 className="widget-title">Business News</h2>
                            </div>
                            <div className="col-6 text-right align-self-center">
                                <Link to="/" className="see_all mb20">See All</Link>
                            </div>
                        </div>}
                    <div className="row">
                        <div className="col-12">
                            {data?.map((item, i) => (
                                <div key={i} className="single_post post_type3 post_type12 mb30">
                                    <div className="post_img">
                                        <div className="img_wrap">
                                            <Link to={`/post/${item?.attributes?.title?.replaceAll(" ","-")}/${item?.id}`}>
                                                <img style={{width:350,height:250}} src={item?.attributes?.url}  alt="thumb"/>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="single_post_text">
                                        <div className="meta3"><Link to={`/category/${item?.attributes?.categoryTitle?.replaceAll(" ","-")}/${item?.attributes?.category_id}`}>{item?.attributes?.categoryTitle}</Link>
                                            <Link to="#">{formatDate(item?.attributes?.publishedOn ? item?.attributes?.publishedOn : item?.attributes?.createdAt)} - {item?.attributes?.readingTime}</Link>
                                        </div>
                                        <h4><Link to={`/post/${item?.attributes?.title?.replaceAll(" ","-")}/${item?.id}`} className="post-ellipse">{item?.attributes?.title}</Link></h4>
                                        <div className="space-10"/>
                                        <p className="post-p">{item.attributes?.content && markdownToTxt(item.attributes?.content)}</p>
                                        <div className="space-20"/>
                                        <Link to={`/post/${item?.attributes?.title?.replaceAll(" ","-")}/${item?.id}`} className="readmore">Read more</Link>
                                    </div>
                                </div>
                            ))}

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BusinessNews;
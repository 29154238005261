import React, { Fragment } from "react";
import { toast, ToastContainer } from "react-toastify";
import { connect } from "react-redux";
import Routes from "../__Routes";
import ScrollTopButton from "../../components/ScrollTopButton";
import LoadingOverlay from 'react-loading-overlay';
import { Rings } from "react-loader-spinner";

const App = (props) => {
    const { error, success, loading } = props;
    if (error) toast.error(error);
    if (success) toast.success(success);

    return (
        <Fragment>
            {/* {props.loading && <h1>loading...</h1>} */}

            <LoadingOverlay
                active={loading}
                spinner={
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <img src={require("../../assets/img/logo/logo.png")} className="loader-logo" />
                        <Rings
                            height="80"
                            width="80"
                            color="#edbc7a"
                            radius="6"
                            wrapperStyle={{ marginTop: 10 }}
                            wrapperClass=""
                            visible={true}
                            ariaLabel="rings-loading"
                        />
                    </div>}
            // text='Loading your content...'
            >

                <Routes />
            </LoadingOverlay>
            <ToastContainer position="top-center" />
            <ScrollTopButton />
        </Fragment>
    );
};

const MapStateToProps = ({ meta, articlesReducer }) => {
    return {
        error: meta.error,
        success: meta.success,
        loading: articlesReducer.loader
    }
};

export default connect(MapStateToProps)(App);

function mostViewSort(data) {
    let initial = 0;
    let secondFirst = Math.floor(data.length / 2) - 1;
    let results = [];
    data.forEach((item, i) => {
        if (i % 2) {
            secondFirst += 1;
            results.push({ ...data[secondFirst], rank: secondFirst + 1 });
        } else {
            initial += 1;
            results.push({ ...data[initial], rank: initial });
        }
    });
    return results;
}

function formatDate(dateStr) {
    const date = new Date(dateStr);
    const formattedDate = date.toLocaleDateString("en-US", { month: "long", day: "numeric", year: "numeric" });
    return formattedDate;
}

function getFormattedDate() {
    const today = new Date();

    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const monthsOfYear = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    const dayOfWeek = daysOfWeek[today.getDay()];
    const month = monthsOfYear[today.getMonth()];
    const day = today.getDate();
    const year = today.getFullYear();

    const formattedDate = `${dayOfWeek}, ${month} ${day}, ${year}`;

    return formattedDate;
}

export {
    mostViewSort,
    getFormattedDate,
    formatDate
};
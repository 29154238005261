import React, { Fragment, useState, useEffect } from 'react';
import BreadCrumb from '../../components/BreadCrumb';
import { addSubscriptionAction, resetArticleReducer } from '../../store/actions';
import { connect } from 'react-redux';
import NewsLetter from '../../components/NewsLetter';
import { toast } from "react-toastify";
import { Helmet } from 'react-helmet';

const options = [
    {
        "title": "Grow Your Brand",
        "description": "Use our industry leading network events as a source to engage with the right target groups and ensure higher brand visibility.",
        "icon": require("../../assets/img/icons/reliability.png")
    },
    {
        "title": "Be Recognized as a Thought Leader",
        "description": "Showcase your expertise by engaging in meaningful dialogues alongside Industry Behemoths, top of the line CEO’s and Credible Influencers.",
        "icon": require("../../assets/img/icons/leadership.png")
    },
    {
        "title": "Display Your Products and Services",
        "description": "Increase brand awareness especially for your recent innovations by exhibiting your products or services at our multiple event platforms.",
        "icon": require("../../assets/img/icons/new-product.png")
    },
    {
        "title": "Receive Media Coverage",
        "description": "Ensure the perfect 360 marketing mix by taking advantage of the various brand integration channels available with us.",
        "icon": require("../../assets/img/icons/social-media.png")
    },
    {
        "title": "Generate Business Leads",
        "description": "Benefit from post-event data capture to help gain maximum return on your investment thru generation of qualified business leads.",
        "icon": require("../../assets/img/icons/research.png")
    },
    {
        "title": "Curate an Exclusive Brand Out-Reach Campaign",
        "description": "Take advantage of our multi decade experience in audience engagement, to activate your brand in the most interactive ways with your specific target audiences.",
        "icon": require("../../assets/img/icons/campaign.png")
    }
]
const PrivacyPolicy = ({ subscriptionAdded, addSubscriptionAction, resetArticleReducer }) => {
    const [modal, setModal] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    useEffect(() => {
        if (subscriptionAdded) {
            toast.success("Submitted Successfully")
            toggle()
            resetArticleReducer()
        }
    }, [subscriptionAdded])

    const toggle = () => setModal(!modal);

    const handleAddData = (data) => {
        addSubscriptionAction(data)
    }

    return (
        <Fragment>
            <Helmet>
                <title>Repute - Partner with us</title>
                <meta name="description" content="Repute is a business media firm established to bring together entities of repute." />
            </Helmet>
            <BreadCrumb className="shadow5" title="Partner with Us" />
            <div className="contacts mt-3 partner_withus_ctr">
                <div className="container">
                    <div className="row">
                        {options.map((item) => (
                            <div className="col-lg-4">
                                <div className="partner-card p-3">
                                    <div><img src={item.icon} /></div>
                                    <h4>{item.title}</h4>
                                    <p>{item.description}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="space-20" />
                    <div className="col-12 d-flex justify-content-center align-items-center">
                        <NewsLetter title={"PARTNER WITH US"} field_type="partnership" modal={modal} handleAddData={handleAddData} handleClick={toggle} customButton={<button onClick={toggle} className="cbtn1" type="submit">Partner with Us</button>} />
                    </div>
                    <div className="space-20" />
                </div>
                <div className="space-20" />
            </div>
        </Fragment>
    )

}

const mapStateToProps = ({ articlesReducer }) => {
    const { subscriptionAdded } = articlesReducer
    return { subscriptionAdded }
}

const mapActionsToProps = {
    addSubscriptionAction,
    resetArticleReducer,
}

export default connect(mapStateToProps, mapActionsToProps)(PrivacyPolicy)
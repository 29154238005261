import * as constants from "../constants";
import { toast } from "react-toastify";

const init = {
    isLoading: false,
    error: '',
    success: '',
    articles: [],
    categories: [],
    loader: true,
    articleById: {},
    subscriptionAdded: false,
    contactUsAdded: false,
    trendingTopics: [],
    newsLetterAdded: false,
    newsLetterEmailAdded:false
};

const articlesReducer = (state = init, action) => {
    switch (action.type) {
        case constants.LOAD_ARTICLES_DATA:
            return {
                ...state,
                isLoading: true,
            };
        case constants.LOAD_ARTICLES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                articles: action.payload?.filter((e) => e.attributes.categoryTitle !== "Trending stories"),
                loader: false
            };
        case constants.LOAD_ARTICLES_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        case constants.LOAD_CATEGORIES_DATA:
            return {
                ...state,
                isLoading: true
            };
        case constants.LOAD_CATEGORIES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                categories: action.payload,
                loader: false
            };
        case constants.LOAD_CATEGORIES_FAILED:
            return {
                ...state,
                isLoading: false
            };
        case constants.LOAD_ARTICLES_BY_ID_DATA:
            return {
                ...state,
                isLoading: true,
            };
        case constants.LOAD_ARTICLES_BY_ID_SUCCESS:
            return {
                ...state,
                isLoading: false,
                articleById: action.payload[0],
                loader: false
            };
        case constants.LOAD_ARTICLES_BY_ID_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        case constants.ADD_SUBSCRIPTION_DATA:
            return {
                ...state,
                isLoading: true,
            };
        case constants.ADD_SUBSCRIPTION_SUCCESS:
            return {
                ...state,
                isLoading: false,
                subscriptionAdded: true,
            };
        case constants.ADD_SUBSCRIPTION_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        case constants.ADD_NEWSLETTER_DATA:
            return {
                ...state,
                isLoading: true,
            };
        case constants.ADD_NEWSLETTER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                newsLetterAdded: true,
            };
        case constants.ADD_NEWSLETTER_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        case constants.ADD_NEWSLETTER_EMAIL_DATA:
            return {
                ...state,
                isLoading: true,
            };
        case constants.ADD_NEWSLETTER_EMAIL_SUCCESS:
            return {
                ...state,
                isLoading: false,
                newsLetterEmailAdded: true,
            };
        case constants.ADD_NEWSLETTER_EMAIL_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        case constants.ADD_CONTACT_DATA:
            return {
                ...state,
                isLoading: false,
            };
        case constants.ADD_CONTACT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                contactUsAdded: true,
            };
        case constants.ADD_CONTACT_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        case constants.LOAD_TRENDING_CATEGORIES_DATA:
            return {
                ...state,
            };
        case constants.LOAD_TRENDING_CATEGORIES_SUCCESS:
            return {
                ...state,
                trendingTopics: action.payload,
            };
        case constants.LOAD_TRENDING_CATEGORIES_FAILED:
            return {
                ...state,
            };
        case constants.RESET_ARTICLES_REDUCER_STATE:
            return {
                ...state,
                subscriptionAdded: false,
                contactUsAdded: false,
                newsLetterAdded: false,
                newsLetterEmailAdded:false
            };
        default:
            return state;
    }
};

export default articlesReducer;